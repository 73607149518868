import React from "react";

export const GalleryUpdated = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Projets</h2>
          <p style={{ color: "#2e3191", fontSize: 18, fontWeight: 700 }}>
            Grâce à la confiance de nos valeureux partenaires , nous avons lancé
            d’innombrables actions marketing couvrant nos divers champs
            d’expertise. Nous en sommes fiers.​ Découvrez quelques uns de nos
            projets​
          </p>
        </div>
        <div
          id="carousel-gallery"
          className="carousel slide"
          data-ride="carousel"
          data-interval="5000"
          data-pause="false"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carousel-gallery"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carousel-gallery" data-slide-to="1"></li>
            <li data-target="#carousel-gallery" data-slide-to="2"></li>
            <li data-target="#carousel-gallery" data-slide-to="3"></li>
            <li data-target="#carousel-gallery" data-slide-to="4"></li>
          </ol>
          <div className="carousel-inner">
            <div className="item active ">
              <div className="row">
                <div
                  className="col-md-4  image-logo"
                  style={{ alignItems: "center", alignContent: "center" }}
                >
                  <a
                    href="/img/gallery/microsoft_event.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Lancement windows 11 en Tunisie <br/><strong>Lieux:</strong> Hôtel La Cigale Tabarka |<strong> Date:</strong> Octobre 2021  "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/microsoft_event.jpg"
                        alt="img"
                        // className="img-responsive"
                        title=""
                      />
                    </figure>
                  </a>
                </div>

                <div className="col-md-4 image-logo">
                  {/* <div
                    className="carousel-caption"
                    style={{ color: "#2e3191" }}
                  >
                    <strong>Microsoft</strong>
                    <p>Date: 02/02/2022</p>
                    <p>Lieux: Movenpick</p>
                    <p>Objectif: Teambuilding</p>
                  </div> */}
                  <a
                    href="/img/gallery/hpe_event.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> HPE Partner Event <br/><strong>Lieux:</strong> Movenpick Lac |<strong> Date:</strong> Mars 2022  "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/hpe_event.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>

                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/dell_event.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Lancement nouvelle génération device Dell <br/> <strong>Lieux:</strong> Novotel Lac 2 | <strong>Date:</strong> Décembre 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/dell_event.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/it_event.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> HPE <br/> <strong>Lieux:</strong> Movenpïck Lac 1 Tunis | <strong>Date:</strong> Février 2022 <br/> "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/it_event.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/fortinet.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Fortinet <br/> <strong>Lieux:</strong> Novotel Tunis | <strong>Date:</strong> Mai 2022 <br/> "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/fortinet.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/win11_event.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Formation Microsoft <br/> <strong>Lieux:</strong> La Cigale | <strong>Date:</strong> Octobre 2021 <br/> "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/win11_event.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
              </div>
            </div>
            <div className="item  ">
              <div className="row">
                <div className="col-md-4 image-logo">
                  {/* <div
                    className="carousel-caption"
                    style={{ color: "#2e3191" }}
                  >
                    <strong>Land'Or</strong>
                    <p>Date: 02/02/2022</p>
                    <p>Lieux: Movenpick</p>
                    <p>Objectif: Promotion</p>
                  </div> */}
                  <a
                    href="/img/gallery/teambuilding_5.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Team Building Adwya <br/> <strong>Lieux:</strong> Golf Djerba | <strong>Date:</strong> Juin 2022 <br/> "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/teambuilding_5.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/adwya.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Team Building Adwya </br/> <strong>Lieux:</strong> Maison d'hôte | <strong>Date:</strong> Février 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/adwya.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/teambuilding_1.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Team Builing Adwya <br/> <strong>Lieux:</strong> Maison d'hôte | <strong>Date:</strong> Février 2022  "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/teambuilding_1.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/cours_musique.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Team Builing Percussion / Adwya <br/> <strong>Lieux:</strong> Golf Club Djerba | <strong>Date:</strong> Juin 2022  "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/cours_musique.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/hotel.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Team Building Microsoft <br/> <strong>Lieux:</strong> La Cigale | <strong>Date:</strong> Novembre 2022  "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/hotel.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/adwya_teambuilding.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Team Building Adwya <br/> <strong>Lieux:</strong> Golf Club Sousse | <strong>Date:</strong> Mai 2022  "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/adwya_teambuilding.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
              </div>
            </div>
            <div className="item  ">
              <div className="row">
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/landor_7.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Lancement fromage squeeze Land'Or <br/> <strong>Lieux:</strong> Circus Gammarth | <strong>Date:</strong> Février 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/landor_7.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/landor_8.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Lancement fromage squeeze Land'Or <br/> <strong>Lieux:</strong> Circus Gammarth | <strong>Date:</strong> Février 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/landor_8.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/landor_2.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Soirée Rbou5 Land'Or <br/> <strong>Lieux:</strong> Yüka Gammarth | <strong>Date:</strong> Juillet 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/landor_2.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/gms_brandt.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Co-branding Land'Or Brandt <br/> <strong>Lieux:</strong> Magasin Général | <strong>Date:</strong> Mai 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/gms_brandt.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>

                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/landor_5.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Dégustation et show cooking Land'Or <br/> <strong>Lieux:</strong> ToBe Carthage | <strong>Date:</strong> Décembre 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/landor_5.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>

                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/landor_4.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Partenariat et dégustation Land'Or  <br/> <strong>Lieux:</strong> ToBe Carthage | <strong>Date:</strong> Décembre 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/landor_4.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/landor_street.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Carnaval Yasmine Hammamet <br/> <strong>Lieux:</strong> Médina Yasmine Hammamet | <strong>Date:</strong> Juin 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/landor_street.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/volley_beach.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Team Building beach volley-ball <br/> <strong>Lieux:</strong> Hammamet | <strong>Date:</strong> Septembre 2021 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/volley_beach.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>

                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/payage_danup.jpeg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Opération street Délice Danone <br/> <strong>Lieux:</strong> Payage Sidi Thebet | <strong>Date:</strong> Juillet 2017 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/payage_danup.jpeg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>

                <div className="col-md-4 image-logo">
                  {/* <div
                    className="carousel-caption"
                    style={{ color: "#2e3191" }}
                  >
                    <strong>HPE</strong>
                    <p>Date: 02/02/2022</p>
                    <p>Lieux: Movenpick</p>
                    <p>Objectif: Conference</p>
                  </div> */}
                  <a
                    href="/img/gallery/landor_event.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Dégustation et showcooking Land'Or <br/> <strong>Lieux:</strong> La Médina Yasmine Hammamet | <strong>Date:</strong> Juin 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/landor_event.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/handball.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Sponsoring Sportif / Fédération Tunisienne de Handball <br/> <strong>Lieux:</strong> Salle Omnisports de Radés | <strong>Date:</strong> Juin 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/handball.jpg"
                        alt="img"
                        title=""
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/landor_brandt.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Dégustation et showcooking Land'Or <br/> <strong>Lieux:</strong> Carrefour La Marsa | <strong>Date:</strong> Mars 2023 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/landor_brandt.jpg"
                        alt="img"
                        title=""
                      />
                    </figure>
                  </a>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/event_kids.jpeg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Opération street Délice Danone <br/> <strong>Lieux:</strong> Parc Sidi Bou Saïd | <strong>Date:</strong> MArs 2016 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/event_kids.jpeg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>

                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/lavage.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> PLV Stop trottoir / Shell <br/> <strong>Lieux:</strong> Station de services | <strong>Date:</strong> Octobre 2021 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/lavage.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>

                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/vitalait.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Dégustation produits Vitalait <br/> <strong>Lieux:</strong> École | <strong>Date:</strong> Mars 2023 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/vitalait.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/kia_event.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> PLV Stop trottoir / KIA <br/> <strong>Lieux:</strong> KIA Le Kram | <strong>Date:</strong> Juin 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/kia_event.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/landor_10.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Dégustation Squeeze Land'Or <br/> <strong>Lieux:</strong> La Médina Yasmine Hammamet | <strong>Date:</strong> Juillet 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/landor_10.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-md-4 image-logo">
                  <a
                    href="/img/gallery/landor_11.jpg"
                    className="ubea-card-item image-popup"
                    title="<strong>Évènement:</strong> Dégustation Squeeze Land'Or <br/> <strong>Lieux:</strong> Épicerie Ben Yaghlane | <strong>Date:</strong> Juin 2022 "
                  >
                    <figure>
                      <img
                        src="/img/gallery/resized/landor_11.jpg"
                        alt="img"
                        title=""
                        // className="img-responsive"
                      />
                    </figure>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
