import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div>
          <div className="container" style={{ width: "100vw" }}>
            <div className="row">
              <div
                id="carousel-header"
                className="carousel slide"
                data-ride="carousel"
                data-pause="false"
              >
                <div className="carousel-inner" role="listbox">
                  <div className="item active carousel-image bg-img-1"></div>
                  <div className="item carousel-image bg-img-2"></div>
                  <div className="item carousel-image bg-img-3"></div>
                  <div className="item carousel-image bg-img-4"></div>
                  <div className="item carousel-image bg-img-5"></div>
                  <div className="item carousel-image bg-img-6"></div>
                  {/* <div className="item carousel-image bg-img-7"></div> */}
                </div>

                <a
                  className="left carousel-control"
                  href="#carousel-header"
                  role="button"
                  data-slide="prev"
                >
                  <span className="icon-prev" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="right carousel-control"
                  href="#carousel-header"
                  role="button"
                  data-slide="next"
                >
                  <span className="icon-next" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
