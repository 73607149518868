import React from "react";
import { useState } from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    console.log(process.env.REACT_APP_SERVICE_ID);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          toast.info("Message envoyé avec succés!");
        },
        (error) => {
          console.log(error.text);
          toast.error("Une erreur s'est produite!");
        }
      );
  };
  return (
    <div id="contact">
      <div className="container">
        <div className="col-md-4">
          <div className="row">
            <div className="section-title">
              <h2>Nous Contacter</h2>
              <p>
                Veuillez remplir le formulaire ci-dessous pour nous envoyer un
                e-mail et nous vous répondrons dans les plus brefs délais.
              </p>
            </div>
            <form name="sentMessage" validate onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      style={{ borderRadius: 10 }}
                      placeholder="Nom"
                      required
                      onChange={handleChange}
                      value={name}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      style={{ borderRadius: 10 }}
                      placeholder="Email"
                      required
                      onChange={handleChange}
                      value={email}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  style={{ borderRadius: 10 }}
                  rows="4"
                  placeholder="Message"
                  required
                  onChange={handleChange}
                  value={message}
                ></textarea>
                <p className="help-block text-danger"></p>
              </div>
              <div id="success"></div>
              <button type="submit" className="btn btn-custom btn-lg">
                Envoyer Un Message
              </button>
            </form>
          </div>
        </div>
        <div className="col-md-3 col-md-offset-1 contact-info">
          <div className="contact-item">
            {/* <h3 style={{ fontWeight: "bold" }}>
                <br />
              </h3> */}
            <p>
              <span>
                <i className="fa fa-map-marker"></i> Adresse:
              </span>
              Immeuble Mamia Palace, <br />
              81 Avenue Habib Bourguiba, Ariana
            </p>
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-phone"></i> Tel.:
              </span>{" "}
              {props.data ? props.data.phone : "loading"}
            </p>
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-mobile"></i> Tel. de recrutement:
              </span>{" "}
              {props.data ? props.data.recrutement_phone : "loading"}
            </p>
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-envelope-o"></i> Email:
              </span>{" "}
              {props.data ? props.data.email : "loading"}
            </p>
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="contact-item">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d671.1460549935831!2d10.185362217905586!3d36.853810901668496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35a1e5d73c27%3A0xa69de8df8d26264c!2sGu%C3%A9rrilla%20com!5e0!3m2!1sfr!2stn!4v1677226075315!5m2!1sfr!2stn"
              height={"55%"}
              width={"100%"}
              className="map"
              about="video"
              allowfullscreen=""
              loading="lazy"
              title="map"
            ></iframe>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="social">
              <ul>
                <li>
                  <a
                    href={props.data ? props.data.facebook : "/"}
                    target="_blank"
                    rel="noreferrer"
                    title="Notre Facebook"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href={props.data ? props.data.instagram : "/"}
                    target="_blank"
                    rel="noreferrer"
                    title="Notre Instagram"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    // href={props.data ? props.data.whatsapp : "/"}
                    href={"https://wa.me/+21650699800"}
                    target="_blank"
                    rel="noreferrer"
                    title="Discuter sur WhatsApp"
                  >
                    <i className="fa fa-whatsapp"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="row">
              <div id="footer">
                <div className="text-center">
                  <p>Guerrilla Com &copy; 2023 Tous droits réservés</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
