import React from "react";
import AnimatedText from "react-animated-text-content";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 ">
            <div className="about-text">
              <h2>A propos</h2>
              <p>
                La création de Guerrilla Com est l’aboutissement de plus de 15
                ans d’expérience de notre fondateur dans le secteur de
                l’évènementiel en Tunisie. Nos prestations de prédilection sont
                les opérations de pousse à la vente, les activations marketing,
                les animations GMS, le street marketing et les gadgets
                promotionnels. D’ailleurs, vous avez certainement vu ou même
                acheté certains des gadgets que nous avons introduits en Tunisie
                pour des opérations de promotions pour des leaders nationaux en
                FMCG (Yaourt, fromage, biscuit, shampoing ...) Ces dernières
                années, nous avons ajouté un autre arc à notre corde. Il s'agit
                des team building: un créneau qu'on a su conquérir et gagner la
                confiance de grands compte vu notre maitrise de l'aspect
                organisation, logistique et réflexion stratégique sur les
                concepts et ateliers à faire.
              </p>
              {/* <br /> */}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                // position: "relative",
                paddingTop: 80,

                // width: "50%",
                // height: "50%",
                // marginTop: 180,
              }}
            >
              <a
                href="/videos/about.mp4"
                data-fancybox
                data-auto-focus="false"
                // data-width="70%"
                // data-height="360"
              >
                <img
                  src="/img/about-frame.jpg"
                  alt="video"
                  style={{
                    borderRadius: 10,
                    backgroundPosition: "center",
                    width: "100%",
                    height: 350,
                    border: "solid 2px #2e3191",
                  }}
                />
                <div class="playpause"></div>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div
              style={{
                color: "#2e3191",
                fontWeight: "bold",
                fontSize: 25,
                textAlign: "center",
                marginTop: 30,
              }}
            >
              <AnimatedText
                type="words" // animate words or chars
                animation={{
                  x: "200px",
                  y: "-20px",
                  scale: 1.1,
                  ease: "ease-in-out",
                }}
                animationType="float"
                interval={0.06}
                duration={0.8}
                // tag="p"
                includeWhiteSpaces
                threshold={0.1}
                rootMargin="20%"
              >
                Envie de nous rencontrer ou de profiter de notre expérience?
              </AnimatedText>

              {/* <a
                href="#contact"
                style={{ color: "#2e3191", textDecoration: "underline" }}
              >
                <strong>Contactez-nous</strong>
              </a> */}
              <AnimatedText
                type="words" // animate words or chars
                animation={{
                  x: "200px",
                  y: "-20px",
                  scale: 1.1,
                  ease: "ease-in-out",
                }}
                animationType="block"
                interval={0.06}
                duration={0.8}
                tag="a"
                href={"#contact"}
                includeWhiteSpaces
                threshold={0.1}
                rootMargin="20%"
              >
                Contactez-nous!
              </AnimatedText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
