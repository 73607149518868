import React from "react";
// import Teambuilding from "./services/Teambuilding";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nos Services</h2>
          <p>
            Guerrilla Com met à votre disposition son expérience confirmée dans
            six différentes prestations. Découvrez les!
          </p>
        </div>
        <div className="row">
          {/* <Teambuilding /> */}
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <i>
                    <img src={d.iconPath} alt="icon" className="service-logo" />
                  </i>

                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p style={{ textAlign: "justify", marginBottom: 30 }}>
                      {d.text}
                    </p>

                    {/* <button className="btn-service btn-custom-service btn-lg">
                      lire plus
                    </button> */}
                    <a
                      id={"#" + d.name.replace(/\s/g, "")}
                      href={"#" + d.name.replace(/\s/g, "")}
                      data-toggle="modal"
                      data-target={"#" + d.name.replace(/\s/g, "")}
                      className="btn-service btn-custom-service btn-lg"
                    >
                      lire plus
                    </a>

                    <div
                      className="modal fade"
                      id={d.name.replace(/\s/g, "")}
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby={"#" + d.name.replace(/\s/g, "")}
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div
                          className="modal-content"
                          style={{
                            background: "#2e3191",
                          }}
                        >
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                              style={{ color: "#fff" }}
                            >
                              &times;
                            </button>
                            <h4
                              className="modal-title"
                              id="myModalLabel"
                              style={{
                                color: "#fff",
                              }}
                            >
                              {d.name}
                            </h4>
                          </div>
                          <div className="modal-body modal-style">
                            <div>
                              <i>
                                <img
                                  src={d.iconLargePath}
                                  alt="icon"
                                  style={{
                                    maxWidth: 200,
                                    maxHeight: 200,
                                  }}
                                />
                              </i>
                            </div>
                            <div className="modal-style-text">
                              {d.paragraph}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
